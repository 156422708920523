export class Search {
  constructor(element) {
    this.element = element;
    const input = element.querySelector('.searchbar__input');

    const formatQuery = function (query) {
      return query.replace(/ /g, '%20');
    };

    input.addEventListener('focus', () => {
      element.classList.add('focus');
    });

    input.addEventListener('blur', () => {
      element.classList.remove('focus');
    });

    element.addEventListener('submit', (event) => {
      const action = this.element.getAttribute('action');
      const searchQuery = this.element.querySelector('input[type="search"]').value;
      const searchScope = this.element.querySelector('input[name=search_scope]:checked').value;

      event.preventDefault();
      console.log(`${action}?k=${formatQuery(searchQuery)}${'' !== searchScope ? '&u=' + searchScope : ''}`);
      location.assign(`${action}?k=${formatQuery(searchQuery)}&u=${searchScope}`);
    });
  }
}
