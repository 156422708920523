import "core-js/stable";
import "regenerator-runtime/runtime";

/* eslint no-unused-vars: "off" */

// Prove JS support
document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js');

// Check SVG support
if (Boolean(document.createElementNS) && Boolean(document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect)) {
  document.documentElement.classList.remove('no-svg');
}

import './scripts/tabs';

import Carousel from './scripts/carousel';
import Collapse from './scripts/collapse';
import Util from './scripts/util';

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.3): index.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

export {
  Util,
  Carousel,
  Collapse,
};


import { setupLazyLoading } from './scripts/lazy';
setupLazyLoading();

// Setup Navigation
import { Navigation } from './scripts/nav';
const navbarEl = document.querySelector('[data-navbar]');

if (navbarEl) {
  const navigation = new Navigation(navbarEl);
}

// Setup Search
import { Search } from './scripts/search';
const searchEl = document.querySelector('.searchbar');

if (searchEl) {
  const search = new Search(searchEl);
}

import { setupSliders } from './scripts/slider';
setupSliders();

// YouTube 2-Click
const youtubeVideos = document.querySelectorAll('.youtube');

if (youtubeVideos.length > 0) {
  import('./scripts/youtube')
    .then((module) => {
      for (let i = 0; i < youtubeVideos.length; i++) {
        const embed = new module.YouTube(youtubeVideos[i]);
      }
    });
}

// Setup FloatBar
const floatBarEl = document.querySelector('#float-bar');

if (floatBarEl) {
  import('./scripts/floatbar')
    .then((module) => {
      const floatBar = new module.FloatBar(floatBarEl);
    });
}
